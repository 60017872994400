import React, { useState } from "react";
import styled from "styled-components";
import CookieBanner from "../components/CookieBanner";
import Footer from "../components/Footer";
import Grid from "../components/layout/Grid";
import LayoutSection from "../components/layout/LayoutSection";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import Paywall from "../components/Paywall";
import SEO from "../components/seo";
import SiteHeader from "../components/SiteHeader";
import colors from "../components/style/colors";
import H3 from "../components/typography/H3";
import P1 from "../components/typography/P1";
import P2 from "../components/typography/P2";
import Button from "../components/ui/Button";
import PopupWrapper from "../components/ui/popupWrapper";
import "../main.css";

const TeamsPage = props => {
  const [paywallVisibility, setPaywallVisibility] = useState(false);

  return (
    <>
      <CookieBanner />
      <LayoutWrapper>
        <SEO title="" canonical={props.location.href} />
        <PopupWrapper
          show={paywallVisibility}
          onOutsideClick={() => {
            setPaywallVisibility(false);
          }}
        >
          <Paywall setPaywallVisibility={setPaywallVisibility} />
        </PopupWrapper>

        <SiteHeader dark />
        <LayoutSection small center>
          <H3 center>Where is Boxy Suite team pricing?</H3>
          <P2>
            We've currently deprecated our team offering because of low demand. If you're interested in a team license
            please <a href="mailto:support@boxyteam.com">let us know</a>.
          </P2>
        </LayoutSection>
        <Footer legal />
      </LayoutWrapper>
    </>
  );
};

export default TeamsPage;
